import React from "react";
import { Trans } from "@lingui/macro";
import { Grid, Button, Typography, Stack, Checkbox, FormControlLabel, Box } from "@mui/material";
import { theme } from "../../themeV2";

function FormStep2({ selectedProducts, handleProductChange }) {
  const productOptions = [
    { key: "mutual_funds", value: "mutual funds", label: "Mutual funds" },
    { key: "equity", value: "equity", label: "Equity" },
    { key: "bonds", value: "bonds", label: "Bonds" },
    { key: "indexes", value: "indexes", label: "Indexes" },
    { key: "etfs", value: "eTFs", label: "ETFs" },
    { key: "precious Metals", value: "precious Metals", label: "Precious Metals" },
    { key: "crypto currencies", value: "crypto currencies", label: "Crypto currencies" },
    { key: "interest rates", value: "interest rates", label: "Interest rates" },
    { key: "currencies", value: "currencies", label: "Currencies" },
    { key: "financial News", value: "financial News", label: "Financial News" },
  ];

  const handleProductToggle = (product) => {
    handleProductChange((prevSelected) =>
      (prevSelected.some((item) => item.value === product.value)
        ? prevSelected.filter((item) => item.value !== product.value)
        : [...prevSelected, product]),
    );
  };

  return (
    <Grid container spacing={2} mt={1} mb={2}>
      <Grid item xs={12} lg={12} mb={2}>
        <Stack mt={4} spacing={1}>
          <Typography variant="h4">
            <Trans>Select products of your interest</Trans>
          </Typography>
          <Stack>
            <Typography>
              <Trans>Select the different products that you need in one entry point.</Trans>
            </Typography>
            <Typography>
              <Trans>Avoid the interaction with different providers and streamline the integration process.</Trans>
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      {productOptions.map((option, index) => (
        <Grid item xs={12} sm={6} md={4} key={index} sx={{ position: "relative" }}>
          <Checkbox
            checked={selectedProducts.some((item) => item.value === option.value)}
            onChange={() => handleProductToggle(option)}
            name={option.value}
            color="secondary"
            sx={{
              position: "absolute",
              left: 30,
              top: "39%",
              pointerEvents: "auto",
              color: theme.palette.colors.grey.main,
            }}
          />
          <Button
            fullWidth
            onClick={() => handleProductToggle(option)}
            sx={{
              minHeight: "72px !important",
              boxShadow: "0px 1px 3px 0px rgba(16, 21, 46, 0.12)",
              ...(selectedProducts.some((item) => item.value === option.value) && {
                border: `1px solid ${theme.palette.colors.red.light}`,
                borderRadius: "10px",
              }),
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              paddingLeft: 4,
              paddingRight: 2,
              position: "relative",
            }}
          >
            <FormControlLabel
              control={<Box sx={{ display: "none" }} />}
              label={
                <Typography color={theme.palette.colors.blue.dark} variant="heading" sx={{ marginLeft: 4 }}>
                  {option.label}
                </Typography>
              }
              sx={{ width: "100%", margin: 0 }}
            />
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}

export default FormStep2;
